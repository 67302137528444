interface CardProps {
  title: string;
  description: string;
  imageUrl: string;
  destinationId: string; // ✅ Add destinationId
}

const MonthlyDestinationCard: React.FC<CardProps> = ({
  title,
  description,
  imageUrl,
  destinationId,
}) => {
  return (
    <div className="bg-white shadow-md rounded-md flex flex-col h-full">
      <img
        src={imageUrl}
        alt={title}
        className="h-[300px] object-cover rounded-t-md"
      />

      <div className="p-4 flex flex-col flex-grow">
        <h3 className="text-base font-bold tracking-wide font-alumnisans">
          {title}
        </h3>
        <p className="text-sm font-poppins text-gray-700 flex-grow overflow-hidden">
          {description}
        </p>
      </div>
    </div>
  );
};

export default MonthlyDestinationCard;
