import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Profile from "../assets/images/TestimonialProfile.png";

interface Testimonial {
  id: string;
  name: string;
  description: string;
  rating: string;
  image: string;
}

interface TestimonialApiResponse {
  success: boolean;
  code: string;
  message: string;
  data: {
    testimonials: Testimonial[];
  };
}

const STATIC_TOKEN = "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const TestimonialView: React.FC = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.post<TestimonialApiResponse>(
          "https://travelnrelax.com/tadmin/api/testimonials/testimonial_list",
          {},
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success && response.data.data.testimonials) {
          setTestimonials(response.data.data.testimonials);
        }
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prev) => (prev + 1 < testimonials.length ? prev + 1 : 0));
    setExpanded(false);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prev) => (prev - 1 >= 0 ? prev - 1 : testimonials.length - 1));
    setExpanded(false);
  };

  return (
    <div className="flex flex-col items-center w-full px-6 py-12">
      <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 uppercase tracking-wide mb-8 text-center">
        Testimonials
      </h2>
      
      <div className="relative flex items-center justify-center w-full max-w-4xl">
        <FaChevronLeft
          className="absolute left-4 text-gray-700 hover:text-gray-900 cursor-pointer text-3xl transition-all duration-300"
          onClick={prevTestimonial}
        />

        <div className="relative w-full max-w-2xl bg-white shadow-xl rounded-xl p-8 text-center">
          {testimonials.length > 0 ? (
            <>
              <div className="flex flex-col items-center">
                <img
                  src={testimonials[currentIndex]?.image || Profile}
                  alt={testimonials[currentIndex]?.name}
                  className="w-24 h-24 rounded-full border-4 border-gray-300 shadow-md mb-4"
                />
                <h3 className="text-xl font-semibold text-gray-900">
                  {testimonials[currentIndex]?.name}
                </h3>
                
                <div className="flex items-center mt-2">
                  {[...Array(5)].map((_, index) => (
                    <svg
                      key={index}
                      className={`w-5 h-5 transition-all ${
                        index < parseInt(testimonials[currentIndex]?.rating || "0")
                          ? "text-yellow-400"
                          : "text-gray-300"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.9c.967 0 1.371 1.24.588 1.81l-3.97 2.879a1 1 0 00-.364 1.118l1.518 4.674c.3.921-.755 1.688-1.538 1.118l-3.97-2.879a1 1 0 00-1.175 0l-3.97 2.879c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.364-1.118L2.993 9.1c-.783-.57-.379-1.81.588-1.81h4.9a1 1 0 00.95-.69l1.518-4.674z" />
                    </svg>
                  ))}
                </div>

                <div className="relative mt-3 px-4">
                  <div className={`text-gray-700 text-base leading-relaxed transition-all duration-500 ${
                    expanded ? "h-auto" : "h-20 overflow-hidden"
                  }`}>
                    {testimonials[currentIndex]?.description}
                  </div>

                  {testimonials[currentIndex]?.description.length > 150 && (
                    <button
                      className="mt-3 text-sm font-semibold text-blue-600 transition-all duration-300 hover:text-blue-700 hover:underline"
                      onClick={() => setExpanded(!expanded)}
                    >
                      {expanded ? "Read Less" : "Read More"}
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <p className="text-gray-500">No testimonials available.</p>
          )}
        </div>

        <FaChevronRight
          className="absolute right-4 text-gray-700 hover:text-gray-900 cursor-pointer text-3xl transition-all duration-300"
          onClick={nextTestimonial}
        />
      </div>
    </div>
  );
};

export default TestimonialView;