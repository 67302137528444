import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData, ApiResponse } from "../services/api";

interface CertificationData {
  certifications: {
    id: string;
    text: string;
    description: string;
    buttonLink?: string;
    buttonText?: string;
  }[];
}

const DirectorCertification: React.FC = () => {
  const [certifications, setCertifications] = useState<
    CertificationData["certifications"][0] | null
  >(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCertifications = async () => {
      try {
        const response: ApiResponse<CertificationData> | null =
          await fetchData<CertificationData>({
            url: "pages/get",
            data: { pageId: 27 },
          });

        console.log("API Response:", response);

        if (response?.data?.certifications?.length) {
          setCertifications(response.data.certifications[0]);
        } else {
          setError("No certification data found.");
        }
      } catch (err) {
        setError("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCertifications();
  }, []);

  return (
    <div className="p-8 sm:p-8 text-center rounded-2xl">
      {loading ? (
        <p className="text-center text-gray-600">Loading...</p>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : certifications ? (
        <>
          {/* Title */}
          <h2 className="text-2xl sm:text-4xl tracking-widest font-semibold text-gray-800 mb-6 font-mplus2">
            {certifications.text}
          </h2>

          {/* Certification List */}
          <ul className="text-left mx-auto max-w-2xl space-y-6 text-gray-800">
            {certifications.description.split("\r\n").map((cert, index) => (
              <li
                key={index}
                className="flex items-start space-x-2 pl-6 relative before:content-[''] before:absolute before:left-0 before:top-2 before:h-3 before:w-3 before:bg-blue-500 before:rounded-full"
              >
                <span className="text-base sm:text-lg font-normal leading-8">
                  {cert}
                </span>
              </li>
            ))}
          </ul>

          {/* Footer */}
          <p className="mt-10 text-lg sm:text-xl text-gray-700 font-medium font-mplus2 font-light">
            Rob looks forward to helping you connect with the world through
            travel! Whether it’s planning your dream vacation or a special
            getaway, Rob is here to assist.
          </p>

          {certifications.buttonLink && certifications.buttonText && (
            <div className="mt-8">
              <Link to={certifications.buttonLink}>
                <button className="py-1 px-6 border border-gray-800 uppercase text-gray-800 font-semibold rounded-lg hover:bg-gray-800 hover:text-white transition-all">
                  {certifications.buttonText}
                </button>
              </Link>
            </div>
          )}
        </>
      ) : (
        <p className="text-center text-gray-600">No data available.</p>
      )}
    </div>
  );
};

export default DirectorCertification;
