import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface TestimonialData {
  Testimonial: {
    id: string;
    text: string;
    description: string;
  }[];
}

const DirectorTestimonial: React.FC = () => {
  const [testimonial, setTestimonial] = useState<TestimonialData["Testimonial"][0] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response: ApiResponse<TestimonialData> | null = await fetchData<TestimonialData>({
          url: "pages/get",
          data: { pageId: 27},
        });

        console.log("API Response:", response);

        if (response?.data?.Testimonial?.length) {
          setTestimonial(response.data.Testimonial[0]);
        } else {
          setError("No testimonials found.");
        }
      } catch (err) {
        setError("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <section className="bg-gradient-to-b from-gray-100 to-white py-16 px-6">
      <div className="max-w-5xl mx-auto text-center">
        {/* Heading Section */}
        <h2 className="text-3xl md:text-4xl font-extrabold mb-6 font-akshar text-gray-800">
          Testimonials
        </h2>
        <div className="w-20 h-1 bg-yellow-500 mx-auto mb-8 rounded"></div>

        {loading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : testimonial ? (
          <div className="relative bg-white shadow-lg rounded-xl p-8 md:p-12">
            <svg
              className="absolute top-4 left-4 w-8 h-8 text-yellow-500 opacity-20"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12h6m-6 4h6m-4-8h4m1 0a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V8a2 2 0 012-2h1"
              />
            </svg>
            <p className="text-lg md:text-xl italic font-light text-gray-700 leading-relaxed">
              "{testimonial.description}"
            </p>
            <div className="mt-6">
              <p className="text-sm md:text-base font-semibold text-gray-800">
                – {testimonial.text}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-600">No testimonials available.</p>
        )}

        {/* Decorative Elements */}
        <div className="flex justify-center mt-10">
          <div className="w-8 h-8 rounded-full bg-yellow-500 mx-2 animate-pulse"></div>
          <div className="w-6 h-6 rounded-full bg-yellow-400 mx-2 animate-pulse"></div>
          <div className="w-4 h-4 rounded-full bg-yellow-300 mx-2 animate-pulse"></div>
        </div>
      </div>
    </section>
  );
};

export default DirectorTestimonial;
