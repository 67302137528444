import React, { useState, useEffect } from "react";
import axios from "axios";
import { GoClock } from "react-icons/go";
import contactimage from "../assets/images/Contactbanner.jpg";

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

interface Destination {
  destinationId: string;
  destinationTitle: string;
}

interface TelephoneCode {
  id: string;
  name: string;
  code: string;
}

const RegisterContact: React.FC = () => {
  const initialFormState = {
    fname: "",
    lname: "",
    email: "",
    conf_email: "",
    phone: "",
    telephoneCodes: "",
    start_date: "",
    end_date: "",
    destination: "",
    month: "",
    custom_destination: "",
    people: "1",
    per_head: "11000$",
    comments: "",
    budget: "",
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formatDate = (inputDate: string): string => {
      const date = new Date(inputDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds() || 0).padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const formattedStartDate = formatDate(formData.start_date);
    const formattedEndDate = formatDate(formData.end_date);

    // Determine destination value
    const destinationValue =
      isCustomDestination && formData.custom_destination
        ? formData.custom_destination // Use custom destination if provided
        : formData.destination || "0"; // Use selected destination ID or default to "0"

    const payload = {
      fname: formData.fname,
      lname: formData.lname,
      email: formData.email,
      conf_email: formData.conf_email,
      phone: formData.phone,
      phone_code: formData.telephoneCodes,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      destination: isCustomDestination ? "0" : destinationValue, // Set "0" for custom destination
      custom_destination: isCustomDestination
        ? formData.custom_destination
        : "", // Include custom destination
      month: parseInt(formData.month) || 0,
      people: parseInt(formData.people) || 0,
      per_head: parseInt(formData.per_head) || 0,
      comments: formData.comments || "",
    };

    try {
      console.log("Submitting payload:", payload);
      const response = await axios.post(
        "https://travelnrelax.com/tadmin/api/enquiries/register",
        payload,
        {
          headers: {
            token: STATIC_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFormData(initialFormState);
      } else {
        console.error("Error:", response.data.message);
        alert(
          response.data.message ||
            "Failed to register enquiry. Please try again."
        );
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error("Axios Error:", error.response?.data || error.message);
        alert(`API Error: ${error.response?.data?.message || error.message}`);
      } else {
        console.error("Unknown Error:", error);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  const [formData, setFormData] = useState(initialFormState);
  const [destination, setDestination] = useState<Destination[]>([]);
  const [telephoneCodes, setTelephoneCodes] = useState<TelephoneCode[]>([]);
  const [error, setError] = useState<string>("");
  const [isCustomDestination, setIsCustomDestination] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = e.target;

    // Check if the user selects "Customize Your Destination"
    if (id === "destination" && value === "") {
      setIsCustomDestination(true);
      setFormData((prev) => ({ ...prev, destination: "" })); // Reset destination
    } else if (id === "destination") {
      setIsCustomDestination(false);
      setFormData((prev) => ({ ...prev, destination: value }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }

    if (id === "per_head") {
      const numericValue = parseInt(value, 10);

      // Ensure value is at least 11000 and set error if it's less
      if (numericValue < 11000 || isNaN(numericValue)) {
        setError("The minimum starting cost per person is $11,000 USD.");
        setFormData((prev) => ({
          ...prev,
          [id]: numericValue < 1 || isNaN(numericValue) ? "1" : value, // Reset to "1" if invalid
        }));
      } else {
        setError(""); // Clear error
        setFormData((prev) => ({
          ...prev,
          [id]: value, // Allow valid input
        }));
      }
      return;
    }

    if (id === "people" && parseInt(value) < 1) {
      setFormData((prev) => ({
        ...prev,
        [id]: "1", // Reset to minimum allowed value
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/destinations",
          {},
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setDestination(response.data.data.destinations);
        }
      } catch (error) {
        console.error("Error fetching destinations:", error);
      }
    };

    const fetchTelephoneCodes = async () => {
      try {
        const response = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/telephone_code",
          {},
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setTelephoneCodes(response.data.data.telephoneCodes);
        }
      } catch (error) {
        console.error("Error fetching telephone codes:", error);
      }
    };

    fetchDestinations();
    fetchTelephoneCodes();
  }, []);
  return (
    <div className="py-12  md:px-20">
      <div>
        <div className="text-center mt-2 sm:mt-5 text-xl lg:text-xl font-semibold font-akshar">
          If you’re ready to get away and start your dream <br />
          vacation, give us a call at 303-317-6945
        </div>
        <div className="grid grid-cols-12 gap-8 mt-10">
          <div className="col-span-12 md:col-span-8">
            <div className="border rounded-lg shadow-sm p-5 bg-white">
              <form onSubmit={handleSubmit} className="space-y-6 mt-5">
                <h4 className="font-akshar uppercase border-b font-semibold text-xl">
                  Your Trip
                </h4>
                <div>
                  <label
                    htmlFor="destination"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    Where would you like to go?*
                  </label>
                  <select
                    id="destination"
                    value={isCustomDestination ? "" : formData.destination}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  >
                    <option value="">Select destination</option>
                    {destination.map((dest) => (
                      <option
                        key={dest.destinationId}
                        value={dest.destinationId}
                      >
                        {dest.destinationTitle}
                      </option>
                    ))}
                    <option value="">Customize Your Destination</option>
                  </select>

                  {isCustomDestination && (
                    <div className="mt-3">
                      <textarea
                        id="custom_destination"
                        value={formData.custom_destination}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            custom_destination: e.target.value,
                          }))
                        }
                        placeholder="Write your destination here"
                        className="w-full px-2 py-2 h-12 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        rows={4}
                        required
                      />
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="start_date"
                    className="block font-anekbangla font-semibold text-gray-700 mb-3"
                  >
                    Start Date
                  </label>
                  <input
                    id="start_date"
                    type="datetime-local"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="end_date"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    End Date
                  </label>
                  <input
                    id="end_date"
                    type="datetime-local"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="month"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    When would you like to go?
                  </label>
                  <select
                    id="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  >
                    <option value="">---Select---</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="people"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    How many people are travelling?
                  </label>
                  <input
                    id="people"
                    type="number"
                    value={formData.people}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    min="1"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="per_head"
                    className="block font-semibold text-gray-700 mb-2"
                  >
                    How much would you like to spend per person?*
                  </label>
                  <input
                    id="per_head"
                    type="number"
                    value={formData.per_head}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border ${
                      error ? "border-red-500" : "border-gray-400"
                    } rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    required
                    min="1" // Prevent negative values in browser UI
                  />
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="comments"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    Any other comments or requests?
                  </label>
                  <textarea
                    id="comments"
                    name="comments"
                    onChange={handleInputChange}
                    placeholder="Write Your Comments"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    rows={4}
                  />
                </div>
              </form>
            </div>
            <div className="border rounded-lg shadow-lg p-5 mt-5 bg-white">
              <form onSubmit={handleSubmit} className="space-y-6 mt-5">
                <h4 className="font-akshar uppercase border-b font-semibold text-xl">
                  Your Details
                </h4>
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label
                      htmlFor="fname"
                      className="block font-anekbangla font-semibold text-gray-700 mb-2"
                    >
                      Your first name*
                    </label>
                    <input
                      id="fname"
                      type="text"
                      value={formData.fname}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="lname"
                      className="block font-anekbangla font-semibold text-gray-700 mb-2"
                    >
                      Your last name*
                    </label>
                    <input
                      id="lname"
                      type="text"
                      value={formData.lname}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    Email address*
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="conf_email"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    Confirm email address*
                  </label>
                  <input
                    id="conf_email"
                    type="email"
                    value={formData.conf_email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                <div className="flex space-x-4">
                  <div className="w-2/3">
                    <label
                      htmlFor="telephoneCodes"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Telephone*
                    </label>
                    <select
                      id="telephoneCodes"
                      value={formData.telephoneCodes}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    >
                      <option value="">Select Country Code</option>
                      {telephoneCodes.map((code) => (
                        <option key={code.id} value={code.code}>
                          {code.name} (+{code.code})
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-2/3">
                    <label
                      htmlFor="phone"
                      className="block font-anekbangla font-semibold text-gray-700 mb-2"
                    >
                      Phone number*
                    </label>
                    <input
                      id="phone"
                      type="text"
                      value={formData.phone}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (/^\d*$/.test(value)) {
                          handleInputChange(e);
                        }
                      }}
                      className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                      inputMode="numeric"
                    />
                  </div>
                </div>

                {/* <div>
                    <label
                      htmlFor="destination"
                      className="block font-anekbangla font-semibold text-gray-700 mb-2"
                    >
                      Destination (ID)*
                    </label>
                    <input
                      id="destination"
                      type="text"
                      value={formData.destination}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div> */}

                {/* <p className="text-sm font-poppins leading-5">
                  simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley
                </p> */}

                <button
                  type="submit"
                  className="bg-black text-sm text-white tracking-wide font-medium uppercase py-2 px-2  rounded"
                >
                  Submit Enquiry
                </button>
                {/* <p className="text-sm font-poppins  leading-5 ">
                  simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s.
                </p> */}
              </form>
            </div>
          </div>

          {/* Office Hours Section */}
          <div className="col-span-12 md:col-span-4">
            <div className="bg-white shadow-lg p-6 rounded-lg border text-center">
              <div className="flex flex-col items-center">
                <GoClock size={36} className="text-black" />
                <h3 className="uppercase mt-3 text-xl font-bold font-akshar tracking-wide">
                  Office Hours
                </h3>
                <hr className="w-1/2 my-4 border-gray-300" />
                <ul className="mt-2 font-normal text-lg space-y-2">
                  <li>Monday: 9AM - 10PM</li>
                  <li>Tuesday: 9AM - 10PM</li>
                  <li>Wednesday: 9AM - 10PM</li>
                  <li>Thursday: 9AM - 10PM</li>
                  <li>Friday: 9AM - 10PM</li>
                  <li>Saturday: Closed</li>
                  <li>Sunday: Closed</li>
                </ul>
                <p className="mt-2 text-sm text-gray-600">
                  (Excluding National Holidays)
                </p>
                <hr className="w-1/2 my-4 border-gray-300" />
                <p className="text-lg font-semibold">Call Us Today</p>
                <p className="text-xl font-bold mt-1">303-317-6945</p>
                <p className="text-sm mt-1 text-gray-600">
                  Call us today until 10PM
                </p>
              </div>
            </div>
            <div className="mt-2 rounded">
              {" "}
              <img src={contactimage} alt="Contact Us" className="rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterContact;
