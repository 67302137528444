import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";
import { motion } from "framer-motion";

interface MottoData {
  id: string;
  text: string;
  description: string;
  image: string;
  buttonLink?: string;
  buttonText?: string;
  backgroundImage?: string;
}

const BannerGroupTravel: React.FC = () => {
  const [motto, setMotto] = useState<MottoData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => setOffsetY(window.scrollY * 0.5));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchMottoData = async () => {
      try {
        const response: ApiResponse<{ motto: MottoData[] }> | null =
          await fetchData<{ motto: MottoData[] }>({
            url: "pages/get",
            data: { pageId: 3 },
          });

        const mottoData = response?.data?.motto;

        if (mottoData && mottoData.length > 0) {
          setMotto(mottoData[0]);
        } else {
          throw new Error("No motto data found.");
        }
      } catch (error) {
        setError("Failed to load motto data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMottoData();
  }, []);

  if (loading) return null; // Hides the component while loading
  if (error) return null; // Hides the component on error

  return (
    <div
      className="relative h-screen bg-cover bg-center flex items-center px-6 lg:px-16"
      style={{
        backgroundImage: `url(${motto?.backgroundImage || "/default-background.jpg"})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/20 sm:bg-gradient-to-b sm:from-black/50 sm:via-black/30 sm:to-black/10"></div>

      {motto && (
        <motion.div
          className="relative w-[100%] sm:w-[80%] lg:w-[50%] px-3 py-8 lg:px-12 lg:py-12 
                  bg-black/10 sm:bg-black/10 border border-white/30 
                  shadow-lg rounded-xl text-white text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h2
            className="text-xl sm:text-3xl uppercase lg:text-3xl font-bold font-akshar leading-tight sm:leading-normal"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {motto?.text}
          </motion.h2>

          <motion.div
            className="mt-3 text-xs sm:text-sm font-poppins text-justify sm:text-left max-h-72 sm:max-h-none overflow-y-auto pr-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            {motto?.description}
          </motion.div>

          {motto?.buttonLink && motto?.buttonText && (
            <motion.div
              className="mt-4 sm:mt-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.9 }}
            >
              <a
                href={motto?.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-500 hover:bg-blue-600 transition-all duration-300 
                 text-white font-semibold px-4 py-2 sm:px-6 sm:py-3 rounded-lg shadow-md text-xs sm:text-lg"
              >
                {motto?.buttonText}
              </a>
            </motion.div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default BannerGroupTravel;
