import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { fetchData, ApiResponse } from "../services/api";

interface Testimonial {
  id: string;
  name: string;
  description: string;
  rating: number;
  image: string;
}

const Testimonials: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const navigate = useNavigate();

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchTestimonials = async () => {
      const response: ApiResponse<{ testimonials: Testimonial[] }> | null =
        await fetchData<{ testimonials: Testimonial[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

      if (response?.data?.testimonials?.length) {
        setTestimonials(
          response.data.testimonials.map((testimonial) => ({
            ...testimonial,
            rating: Number(testimonial.rating),
          }))
        );
      }
    };

    fetchTestimonials();
  }, []);

  if (testimonials.length === 0) return null; // ✅ Prevents rendering empty UI

  return (
    <section className="relative md:px-16 p-4 md:p-0 mt-0">
      <h2 className="mt-2 tracking-wide sm:mt-5 text-xl lg:text-3xl font-bold font-akshar mb-2 sm:mb-4 uppercase text-center">
        Testimonials
      </h2>
      <div className="relative flex items-center justify-between">
        <button
          onClick={scrollLeft}
          className="transform text-lg p-2 text-gray-600 hover:bg-violet-950 hover:text-white bg-white w-10 h-10 sm:w-12 sm:h-12 rounded-full shadow hover:shadow-lg focus:outline-none"
        >
          <IoIosArrowBack size={24} />
        </button>

        <div
          ref={scrollRef}
          className="flex mt-4 overflow-x-scroll scrollbar-hide w-full"
        >
          <div className="flex justify-center items-center space-x-4">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                onClick={() => navigate("/testimonials")}
                className="bg-white shadow border rounded-lg w-64 sm:w-72 h-80 sm:h-96 flex-shrink-0 p-4 sm:p-6 flex flex-col items-center justify-between cursor-pointer hover:shadow-xl transition-shadow duration-300"
              >
                <img
                  src={testimonial.image}
                  className="w-20 h-20 sm:w-32 sm:h-32 object-cover object-center mb-4 rounded-full"
                  alt={`${testimonial.name} profile`}
                />
                <h3 className="text-md sm:text-lg uppercase font-semibold font-alumnisans text-center">
                  {testimonial.name}
                </h3>
                <div className="flex items-center mb-2">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <svg
                      key={i}
                      className="w-4 h-4 text-yellow-400 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.9c.967 0 1.371 1.24.588 1.81l-3.97 2.879a1 1 0 00-.364 1.118l1.518 4.674c.3.921-.755 1.688-1.538 1.118l-3.97-2.879a1 1 0 00-1.175 0l-3.97 2.879c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.364-1.118L2.993 9.1c-.783-.57-.379-1.81.588-1.81h4.9a1 1 0 00.95-.69l1.518-4.674z" />
                    </svg>
                  ))}
                </div>
                <p className="text-sm text-gray-700 font-poppins text-justify sm:text-center flex-grow mt-2 mb-4 line-clamp-5">
                  {testimonial.description.length > 150
                    ? `${testimonial.description.substring(0, 150)}...`
                    : testimonial.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={scrollRight}
          className="transform text-lg p-2 text-gray-600 bg-white w-10 h-10 sm:w-12 sm:h-12 rounded-full shadow hover:shadow-lg hover:bg-violet-950 hover:text-white focus:outline-none"
        >
          <IoIosArrowForward size={24} />
        </button>
      </div>
    </section>
  );
};

export default Testimonials;
