import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface MottoData {
  id: string;
  text: string;
  description: string;
  image?: string;
  buttonLink?: string;
  buttonText?: string;
  backgroundImage?: string;
}

const UniversalBanner: React.FC = () => {
  const [motto, setMotto] = useState<MottoData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Detect mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch motto data
  useEffect(() => {
    const fetchMottoData = async () => {
      try {
        const response: ApiResponse<{ motto: MottoData[] }> | null =
          await fetchData<{ motto: MottoData[] }>({
            url: "pages/get",
            data: { pageId: 11 },
          });

        if (response?.data?.motto?.length) {
          setMotto(response.data.motto[0]);
        } else {
          setError("No motto data found.");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchMottoData();
  }, []);

  if (!motto) return null;

  return (
    <div
      className="relative bg-center flex  "
      style={{
        backgroundImage: `url(${motto?.backgroundImage})`,
        backgroundSize: isMobile ? "contain" : "cover", // Mobile: contain, Web: cover
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: isMobile ? "scroll" : "fixed", // Parallax only for web
        minHeight: isMobile ? "300px" : "100vh", // Fixed height for mobile, full screen for web
      }}
    >
        <div className="relative w-[100%] sm:w-[80%] lg:w-[50%] px-3 py-8 lg:px-12 lg:py-12 rounded-xl text-white text-center">
        {loading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <>
            <h2 className="text-xl sm:text-3xl uppercase lg:text-3xl font-bold font-akshar leading-tight sm:leading-normal"></h2>

            <div className="mt-3 text-xs sm:text-sm font-poppins text-justify sm:text-left max-h-72 sm:max-h-none overflow-y-auto pr-2"></div>

            {motto.buttonLink && motto.buttonText && (
              <div className="mt-4 sm:mt-6">
                <a
                  href={motto.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-blue-500 hover:bg-blue-600 transition-all duration-300 text-white font-semibold px-4 py-2 sm:px-6 sm:py-3 rounded-lg shadow-md text-xs sm:text-lg"
                >
                  {motto.buttonText}
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UniversalBanner;
