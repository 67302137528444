import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Right from "../assets/images/Right-Month.svg";
import Left from "../assets/images/LeftMonth.svg";

const TabNavigation = () => {
  const [activeTab, setActiveTab] = useState("month");

  const tabs = [
    { label: "MONTH", value: "month", path: "/month" },
    { label: "INSPIRATION", value: "inspiration", path: "/inspiration/" }, // Add path
    { label: "TRIP FINDER", value: "tripFinder", path: "/trip-finder" },
    { label: "MOST POPULAR", value: "mostPopular", path: "/most-popular" },
  ];

  return (
    <div className="relative">
      <div className="flex flex-col sm:flex-row justify-center mt-10 items-center font-mplus2 border-b max-w-max mx-auto">
        <div className="flex flex-wrap justify-center space-x-4 mt-10">
          {tabs.map((tab) => (
            <Link key={tab.value} to={tab.path}> {/* Use Link for navigation */}
              <button
                onClick={() => setActiveTab(tab.value)}
                className={`pb-2 px-4 ${
                  activeTab === tab.value
                    ? "text-blue-800 font-bold border-b-2 border-blue-800"
                    : "text-gray-500"
                } transition duration-200 ease-in-out`}
              >
                <span className="text-xs sm:text-base">{tab.label}</span> {/* Responsive font size */}
              </button>
            </Link>
          ))}
        </div>
      </div>

      <div className="absolute top-0 right-0 h-[250px] w-28 hidden sm:block">
        <img
          src={Right}
          alt="Right Arrow"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute top-0 left-0 h-[250px] w-28 hidden sm:block">
        <img src={Left} alt="Left Arrow" className="w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default TabNavigation;
