import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { GoClock } from "react-icons/go";
import contactimage from "../assets/images/Contactus.png";
import contactlogo from "../assets/images/contact-logo.png";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import TripPlannerForm from "../components/TripPlannerForm";
import RegisterContact from "../components/RegisterContact";
import ContactSection from "../components/ContactSection";
import ContactUsSection from "../components/ContactUsSection";
import { Helmet } from "react-helmet";
import { fetchData } from "../services/api";

const ContactUs: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [enabled, setEnabled] = useState(false);

  const toggleSwitch = () => {
    setEnabled(!enabled);
  };
  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 2 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle:
              "Denver- Contact Travel N Relax | Top Travel Agency for Customized Vacations & Getaways",
            metaKeyword: meta_keyword,
            metaDescription: meta_description,
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta name="tittle" content={metaData.pageTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
      </Helmet>
      <Header />

      <RegisterContact />
      <ContactUsSection />

      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default ContactUs;
