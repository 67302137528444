import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

interface Blog {
  blogId: string;
  title: string;
  description: string;
  image: string;
  createdAt: string;
  authorName: string | null;
  categoryNames: string[];
}

interface BlogListResponse {
  success: boolean;
  message: string;
  error_code: number;
  data: {
    blogs: Blog[];
  };
}

interface BlogDetailsResponse {
  success: boolean;
  message: string;
  error_code: number;
  data: Blog[];
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const BlogDetails: React.FC = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState<Blog | null>(null);

  const slugify = (text: string) =>
    text.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-+|-+$/g, "");

  useEffect(() => {
    const fetchBlogBySlug = async () => {
      try {
        const blogListRes = await axios.post<BlogListResponse>(
          "https://travelnrelax.com/tadmin/api/Blogs/list",
          {},
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        const blogs = blogListRes.data?.data?.blogs || [];
        const matched = blogs.find(
          (b) => slugify(b.title) === slug
        );

        if (!matched) {
          navigate("/blog");
          return;
        }

        // Now fetch the full blog details using blogId
        const blogDetailsRes = await axios.post<BlogDetailsResponse>(
          `https://travelnrelax.com/tadmin/api/Blogs/details/${matched.blogId}`,
          {},
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (
          blogDetailsRes.data.success &&
          blogDetailsRes.data.data.length > 0
        ) {
          setBlog(blogDetailsRes.data.data[0]);
        } else {
          navigate("/blog");
        }
      } catch (err) {
        console.error("Error fetching blog:", err);
        navigate("/blog");
      }
    };

    if (slug) {
      fetchBlogBySlug();
    }
  }, [slug, navigate]);

  if (!blog) return null;

  return (
    <div className="bg-white min-h-screen py-10 px-4 sm:px-8 lg:px-16">
      <div className="relative">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-[50rem] object-cover"
        />
        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/70 to-transparent text-white p-6 sm:p-8">
          <h1 className="text-4xl font-bold font-serif font-alumnisans">
            {blog.title}
          </h1>
          <p className="mt-3 text-sm font-light font-alumnisans">
            Published on {new Date(blog.createdAt).toLocaleDateString()} by{" "}
            <span className="font-medium">
              {blog.authorName || "Unknown"}
            </span>
          </p>
        </div>
      </div>

      {/* Blog Content */}
      <div className="mt-8">
        <div className="mb-8">
          <h2 className="text-lg font-semibold text-gray-800 font-sans">
            Categories
          </h2>
          <div className="flex flex-wrap gap-3 mt-4">
            {blog.categoryNames.map((category, idx) => (
              <span
                key={idx}
                className="bg-gradient-to-r font-mplus2 from-blue-500 to-purple-500 text-white px-4 py-1 text-sm rounded-full shadow-md font-medium hover:shadow-lg transition"
              >
                {category}
              </span>
            ))}
          </div>
        </div>

        <div className="text-gray-700 leading-relaxed prose prose-lg">
          <h2 className="text-2xl font-bold mb-5">Blog Details</h2>
          <p className="whitespace-pre-wrap font-akshar">{blog.description}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
