import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { fetchData, ApiResponse } from "../services/api";

interface Review {
  id: string;
  name: string;
  description: string;
  rating: number;
}

const Carousel: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      const response: ApiResponse<{ reviews: Review[] }> | null =
        await fetchData<{ reviews: Review[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

      if (response?.data?.reviews) {
        setReviews(response.data.reviews);
      }
    };

    fetchReviews();
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
  });

  return (
    <div {...handlers} className="relative w-full py-8 bg-white overflow-hidden">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {reviews.map((review) => (
          <div
            key={review.id}
            className="min-w-full md:min-w-[50%] lg:min-w-[33.33%] px-4 flex justify-center"
          >
            <div className="bg-white p-4 rounded-lg shadow max-w-sm text-center">
              <div className="text-4xl text-blue-600 mb-2">“</div>
              <p className="text-gray-600 mb-2">{review.description}</p>
              <div className="flex justify-center mb-2">
                {[...Array(5)].map((_, i) => (
                  <span
                    key={i}
                    className={
                      i < review.rating ? "text-yellow-500" : "text-gray-300"
                    }
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="font-bold text-blue-800">{review.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
