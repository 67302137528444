import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import Footerlogo from "../assets/images/HeaderLogo.svg";
import { Link } from "react-router-dom";

const NavMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div>
      <div className="flex justify-between overflow-y-hidden items-center py-4 px-4 md:px-28 bg-gray-50">
        <div className="flex-shrink-0">
          <Link to="/">
            <img src={Footerlogo} alt="Logo" className="h-12 md:h-20" />
          </Link>
        </div>

        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-black">
            <FaBars size={24} />
          </button>
        </div>

        <div className="hidden md:flex items-center justify-center flex-grow space-x-16 text-neutral-100">
          <ul className="hidden md:flex text-black   items-center space-x-2 md:space-x-12  text-sm md:text-lg">
            <li className="hover:text-black text-black font-semibold text-base  uppercase cursor-pointer">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-y-sky-900 pb-1" : ""
                }
              >
                Home
              </NavLink>
            </li>
            <li className="hover:text-violet uppercase font-semibold  text-base   cursor-pointer">
              <NavLink
                to="/destinations/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-y-sky-900 pb-1" : ""
                }
              >
                Destination
              </NavLink>
            </li>

            <li className="hover:text-violet uppercase font-semibold text-base   cursor-pointer">
              <NavLink
                to="/experiences/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-y-sky-900 pb-1" : ""
                }
              >
                Experiences
              </NavLink>
            </li>
            <li className="hover:text-violet uppercase font-semibold text-base  cursor-pointer">
              <NavLink
                to="/month/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-y-sky-900 pb-1" : ""
                }
              >
                Month
              </NavLink>
            </li>

            <li className="hover:text-violet uppercase font-semibold  text-base  cursor-pointer">
              <NavLink
                to="/blog/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-y-sky-900 pb-1" : ""
                }
              >
                Blog
              </NavLink>
            </li>
            <li className="hover:text-violet uppercase font-semibold  text-base  cursor-pointer">
              <NavLink
                to="/go/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-y-sky-900 pb-1" : ""
                }
              >
                 CONTACT US
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Travel Button */}
        <div className="hidden md:block">
          <button>
            <Link
              to="/go/"
              className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
            >
              Ready To Travel?
            </Link>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-90 flex flex-col items-center justify-center text-white ">
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={toggleMenu}
          >
            &times;
          </button>
          <ul className="space-y-4 text-center">
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                Home
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/destinations/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                Destination
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/experiences/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                Experiences
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/months/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                Month
              </NavLink>
            </li>
            <button>
              <Link
                to="/go/"
                className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              >
                Ready To Travel?
              </Link>
            </button>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavMenu;
