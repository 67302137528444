import React, { useState } from "react";
import { Link } from "react-router-dom";
import contactlogo from "../assets/images/contact-logo.png";

const ContactUsSection: React.FC = () => {
  const [enabled, setEnabled] = useState(false);

  const toggleSwitch = () => {
    setEnabled(!enabled);
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-8 sm:mt-16 px-4">
        <h3 className="uppercase text-2xl sm:text-4xl font-semibold font-alumnisans tracking-wide text-center">
          Contact Us Today and See Why We’re Denver’s #1 Travel Agency
        </h3>

        <p className="font-akshar tracking-wide text-center opacity-95 mt-4 sm:mt-5 text-sm sm:text-base">
          <Link to="/honeymoon-geteway" className="">
            Honeymoon Getaways
          </Link>{" "}
          •{" "}
          <Link to="/destination-wedding" className="">
            Destination Weddings
          </Link>{" "}
          •{" "}
          <Link to="/family-travel" className="">
            Family Travel
          </Link>{" "}
          •{" "}
          <Link to="/group-travel" className="">
            Group Travel
          </Link>{" "}
          •{" "}
          <Link to="/anniversary" className="">
            Anniversary
          </Link>{" "}
          •{" "}
          <Link to="/inclusive-vacation" className="">
            All Inclusive Vacation
          </Link>{" "}
          <br className="hidden sm:block" />•{" "}
          <Link to="/ocean-cruises" className="">
            Ocean Cruises
          </Link>{" "}
          •{" "}
          <Link to="/river-cruise" className="">
            River Cruises
          </Link>{" "}
          •{" "}
          <Link to="/disney-vacation" className="">
            Disney Vacations
          </Link>
          <Link to="/ski-vacation" className="">
            Ski Vacations
          </Link>
        </p>

        <div className="mt-4">
          <img
            src={contactlogo}
            alt="Logo"
            className="h-16 sm:h-20 md:h-28 w-auto mx-auto"
          />

          <div className="text-center leading-6 mt-4 sm:mt-5 mb-5">
            <div className="text-xs sm:text-sm font-semibold uppercase">
              TRAVEL N RELAX
            </div>
            <div className="text-xs sm:text-sm uppercase text-black opacity-95">
              15385 E 7th Cir, Denver, Colorado, CO 80011
            </div>
            <div className="text-xs sm:text-sm uppercase text-black opacity-95">
              (303) 317-6945
            </div>
            <div className="text-xs sm:text-sm uppercase text-black opacity-95">
              info@travelnrelax.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
