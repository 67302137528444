import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface Partner {
  id: string;
  title: string;
  image: string;
}

const Clients: React.FC = () => {
  const [partners, setPartners] = useState<Partner[]>([]);

  useEffect(() => {
    const fetchPartners = async () => {
      const response: ApiResponse<{ partners: Partner[] }> | null =
        await fetchData<{ partners: Partner[] }>({
          url: "pages/get",
          data: { pageId: 2 },
        });

      if (response?.data?.partners?.length) {
        setPartners(response.data.partners);
      }
    };

    fetchPartners();
  }, []);

  if (partners.length === 0) return null; // ✅ Prevents rendering empty UI

  return (
    <div className="bg-gray-100 w-full py-8 px-4 md:px-12">
      <div className="clients-container flex flex-wrap justify-around items-center gap-6 md:gap-12">
        {partners.map((partner) => (
          <img
            key={partner.id}
            src={partner.image}
            alt={partner.title}
            className="client-logo w-20 h-auto md:w-32 object-contain"
          />
        ))}
      </div>
    </div>
  );
};

export default Clients;
