import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";
import Leftimage from "../assets/images/Weddingleft.png";

interface PlanningData {
  id: string;
  text: string;
  description: string;
  image?: string;
  buttonLink?: string;
  buttonText?: string;
}

const UniversalStudioPreperation: React.FC = () => {
  const [planning, setPlanning] = useState<PlanningData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningData = async () => {
      try {
        const response: ApiResponse<{ planning: PlanningData[] }> | null =
          await fetchData<{ planning: PlanningData[] }>({
            url: "pages/get",
            data: { pageId: 11 },
          });

        const planningData = response?.data?.planning;

        if (planningData && planningData.length > 0) {
          setPlanning(planningData[0]); // Assuming the first planning item is required
        } else {
          throw new Error("Planning data not found.");
        }
      } catch (error) {
        setError("Failed to load planning data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlanningData();
  }, []);

  return (
    <div className="py-10 px-4 sm:px-8 lg:px-20">
      {/* {planning?.buttonLink && planning?.buttonText && (
      <div className="mt-6 text-center">
        <a
          href={planning.buttonLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded"
        >
          {planning.buttonText}
        </a>
      </div>
    )} */}

      <h3 className=" text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar  text-center uppercase">
        Why Choose Travel N Relax?
      </h3>
      <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
        Planning a Universal Destinations & Experiences vacation can be
        overwhelming, but that’s where we come in. With insider knowledge and
        decades of experience, our Universal travel experts handle every detail,
        ensuring a stress-free and unforgettable adventure. We offer VIP park
        access, personalized itineraries, and exclusive deals so you can focus
        on the fun while we take care of the logistics. Our team ensures you get
        the best accommodations, park passes, and dining experiences, making
        your vacation seamless, exciting, and perfectly tailored to your needs.
      </p>
      <h3 className=" mt-3  text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar  text-center uppercase">
        {planning?.text}
      </h3>
      <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
        {planning?.description}
      </p>
    </div>
  );
};

export default UniversalStudioPreperation;
