import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footerlogo from "../assets/images/HeaderLogo.svg";

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSpecialtiesOpen, setIsSpecialtiesOpen] = useState(false);
  const [isThemeParkOpen, setIsThemeParkOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSpecialties = () => {
    setIsSpecialtiesOpen((prevState) => !prevState);
  };

  const toggleThemePark = () => {
    setIsThemeParkOpen((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    setIsSpecialtiesOpen(false);
    setIsThemeParkOpen(false);
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div>
      <div className="flex justify-between items-center py-4 bg-gray-50 px-4 md:justify-around">
        <div className="flex">
          <Link to="/" className="p-2">
            <img src={Footerlogo} alt="Logo" className="h-16 md:h-20" />
          </Link>
        </div>

        <button className="md:hidden text-gray-900" onClick={toggleMenu}>
          <FaBars size={20} />
        </button>
        <div
          className={`hidden md:flex items-center space-x-12 text-neutral-100`}
        >
          <ul className="flex items-center space-x-4 font-semibold  tracking-wide">
            <li className="hhover:text-black text-black cursor-pointer">
              <Link to="/">HOME</Link>
            </li>
            <div className="relative group hover:text-black text-black cursor-pointer">
              <li>
                {" "}
                <li className="relative group hhover:text-black text-black cursor-pointer">
                  <Link
                    to="/services/"
                    className=" hhover:text-black text-black cursor-pointer uppercase"
                  >
                    Our Specialties
                  </Link>
                  <div className="absolute left-0 uppercase mt-2 w-64 bg-[#ffffff] text-gray-800 rounded-lg shadow-lg z-20 hidden group-hover:block transition-all duration-200 transform-gpu">
                    <ul className="py-2">
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/family-travel/" className="block">
                          Family Travel
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/group-travel/" className="block">
                          Group Travel
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/destinetion-wedding/" className="block">
                          Destination Wedding
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/honeymoon/" className="block">
                          Honeymoon Getaways
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/anniversary/" className="block">
                          Anniversary
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/all-inclusive-vacations/" className="block">
                          All Inclusive Vacation
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/ocean-cruise/" className="block">
                          Ocean Cruise
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/river-cruise/" className="block">
                          River Cruise
                        </Link>
                      </li>
                      <li className="relative group px-4 py-3 hover:bg-gray-100 cursor-pointer rounded transition-colors duration-200">
                        <div className="block">Theme Park Vacation</div>
                        <div className="absolute top-0 left-full ml-2 w-60 bg-[#f8f8f8] text-gray-800 rounded-lg shadow-lg hidden group-hover:block transition-all duration-200 transform-gpu">
                          <ul className="py-2">
                            <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                              <Link to="/disney-vacations/" className="block">
                                Disney Vacation
                              </Link>
                            </li>
                            <li className="px-4 py-3 hover:bg-gray-200 cursor-pointer rounded transition-colors duration-200">
                              <Link
                                to="/universal-studios-vacations/"
                                className="block"
                              >
                                Universal Studios Vacation
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="px-4 py-3 hover:bg-gray-100 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/colorado-ski-vacations/" className="block">
                          Ski Vacation
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </li>

              {isSpecialtiesOpen && (
                <div className="absolute uppercase left-0 mt-2 w-48 bg-blue-900 text-white rounded shadow-lg z-20">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:text-yellow-400">
                      <Link to="/family-travel" onClick={handleLinkClick}>
                        Family Travel
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:text-yellow-400">
                      <Link to="/adventure-travel" onClick={handleLinkClick}>
                        Group Travel
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:text-yellow-400">
                      <Link to="/luxury-travel" onClick={handleLinkClick}>
                        Luxury Travel
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <li>
              {" "}
              <Link
                to="/about-our-travel-agency/"
                className="p-2 hover:text-black text-black"
                onClick={handleLinkClick}
              >
                ABOUT US
              </Link>
            </li>

            <li className="hover:text-black cursor-pointer uppercase hover:text-black text-black">
              {" "}
              <Link to="/blog" className="p-2" onClick={handleLinkClick}>
                Blog
              </Link>
            </li>
            {/* <li>
              {" "}
              <Link
                to="/go"
                className="p-2 hover:text-black text-black"
                onClick={handleLinkClick}
              >
                CONTACT US
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="hidden md:block">
          <button>
            <Link
              to="/go"
              className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
            >
              Ready To Travel?
            </Link>
          </button>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-95 transition-opacity duration-300 ${
          isOpen ? "opacity-100 z-20" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="flex flex-col items-center justify-center h-full space-y-6 p-6">
          <button
            className="absolute top-4 right-4 text-white hover:text-yellow-400 transition duration-300"
            onClick={toggleMenu}
          >
            <FaTimes size={24} />
          </button>
          <ul className="text-white text-center space-y-6 text-lg">
            <li onClick={handleLinkClick}>
              <Link to="/">HOME</Link>
            </li>
            <li
              className="cursor-pointer w-full text-center bg-gray-800 p-3 rounded-md"
              onClick={toggleSpecialties}
            >
              <div className="flex justify-between items-center">
                <span>OUR SPECIALTIES</span>
                <span>{isSpecialtiesOpen ? "▲" : "▼"}</span>
              </div>
            </li>
            {isSpecialtiesOpen && (
              <ul className="bg-gray-700 text-white space-y-3 text-base pl-4 p-4 rounded-lg shadow-lg w-full">
                <li onClick={handleLinkClick}>
                  <Link to="/family-travel/">Family Travel</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/group-travel/">Group Travel</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/destinetion-weddings/">Destination Wedding</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/honeymoon/">Honeymoon Getaways</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/anniversary/">Anniversary</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/all-inclusive-vacations/" >All Inclusive Vacation</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/ocean-cruise/">Ocean Cruise</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/river-cruise/">River Cruise</Link>
                </li>
                <li
                  className="cursor-pointer bg-gray-800 p-3 rounded-md"
                  onClick={toggleThemePark}
                >
                  <div className="flex justify-between items-center">
                    <span>Theme Park Vacation</span>
                    <span>{isThemeParkOpen ? "▲" : "▼"}</span>
                  </div>
                </li>
                {isThemeParkOpen && (
                  <ul className="bg-gray-600 text-white space-y-2 text-sm pl-6 p-3 rounded-lg shadow-md">
                    <li onClick={handleLinkClick}>
                      <Link to="/disney-vacations/">Disney Vacation</Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link to="/universal-studios-vacations/">
                        Universal Studios
                      </Link>
                    </li>
                  </ul>
                )}
                <li onClick={handleLinkClick}>
                  <Link to="/colorado-ski-vacations/">Ski Vacation</Link>
                </li>
              </ul>
            )}
            <li onClick={handleLinkClick}>
              <Link to="/about-our-travel-agency/">ABOUT US</Link>
            </li>
            <li onClick={handleLinkClick}>
              <Link to="/blog/">BLOG</Link>
            </li>
            <li onClick={handleLinkClick}>
              <Link to="/go/">CONTACT US</Link>
            </li>
            <li>
              <button className="bg-blue-900 text-white py-3 px-6 rounded-lg hover:bg-blue-700">
                <Link to="/go/" onClick={toggleMenu}>
                  Ready To Travel?
                </Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
