import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";
import { Link } from "react-router-dom";

interface CatalogData {
  id: string;
  text: string;
  description: string;
  image: string;
  buttonLink: string;
  buttonText: string;
}

const SeamlessTravel: React.FC = () => {
  const [catalogData, setCatalogData] = useState<CatalogData[]>([]);

  useEffect(() => {
    const fetchCatalogData = async () => {
      const response: ApiResponse<{ catalog: CatalogData[] }> | null =
        await fetchData<{ catalog: CatalogData[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

      if (response?.data?.catalog?.length) {
        setCatalogData(response.data.catalog);
      }
    };

    fetchCatalogData();
  }, []);

  if (catalogData.length === 0) return null;

  return (
    <div>
      {catalogData.map((catalog, index) => (
        <section
          key={catalog.id}
          className="bg-white py-12 px-4 md:px-20 relative"
        >
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            {/* Image container */}
            {index % 2 === 0 ? (
              <>
                <div className="col-span-1 md:col-span-6">
                  <div className="w-full h-[300px] md:h-[400px] lg:h-[460px]">
                    <img
                      src={catalog.image}
                      alt={catalog.text}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="col-span-1 md:col-span-6 mt-5 md:mt-0">
                  <div className="rounded-lg">
                    <h2 className="text-xl lg:text-3xl font-bold md:text-4xl mb-4 font-akshar text-center md:text-left">
                      {catalog.text}
                    </h2>
                    <p className="lg:text-base sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-700 text-justify sm:justify-center">
                      {catalog.description}
                    </p>

                    <div className="flex justify-center md:justify-start mt-4">
                      <Link
                        to="/go/"
                        className="py-2 px-6 border border-black text-black font-semibold border-2 rounded-md uppercase hover:bg-gray-700 hover:text-white transition"
                      >
                        Ready To Travel?
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-span-1 md:col-span-6 order-2 md:order-1">
                  <div className="rounded-lg">
                    <h2 className="text-xl lg:text-3xl font-bold md:text-4xl mb-4 font-akshar text-center md:text-left">
                      {catalog.text}
                    </h2>
                    <p className="lg:text-base sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-700 text-justify sm:justify-center">
                      {catalog.description}
                    </p>
                    <div className="flex justify-center md:justify-start mt-4">
                      <Link
                        to="/go/"
                        className="py-2 px-6 border border-black text-black font-semibold border-2 rounded-md uppercase hover:bg-gray-700 hover:text-white transition"
                      >
                        Ready To Travel?
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-6 order-1 md:order-2">
                  <div className="w-full h-[300px] md:h-[400px] lg:h-[460px]">
                    <img
                      src={catalog.image}
                      alt={catalog.text}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      ))}
    </div>
  );
};

export default SeamlessTravel;
