import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface PlanningData {
  id: string;
  text: string;
  description: string;
  image?: string;
  buttonLink?: string;
  buttonText?: string;
}

const SkiVacationPreparation: React.FC = () => {
  const [planningList, setPlanningList] = useState<PlanningData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningData = async () => {
      try {
        const response: ApiResponse<{ planning: PlanningData[] }> | null =
          await fetchData<{ planning: PlanningData[] }>({
            url: "pages/get",
            data: { pageId: 12 },
          });

        const planningData = response?.data?.planning;

        if (planningData && planningData.length > 0) {
          setPlanningList(planningData);
        } else {
          throw new Error("Planning data not found.");
        }
      } catch (error) {
        setError("Failed to load planning data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlanningData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div className="py-10 px-4 sm:px-8 lg:px-20">
      <h3 className="text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar text-center uppercase">
        Plan Your Ski Vacation
      </h3>

      {planningList.map((planning) => (
        <div key={planning.id} className="mt-8">
          <h4 className="text-lg sm:text-2xl font-semibold font-akshar text-center">
            {planning.text}
          </h4>
          <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
            {planning.description}
          </p>
          {planning.buttonLink && planning.buttonText && (
            <div className="mt-6 text-center">
              <a
                href={planning.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded"
              >
                {planning.buttonText}
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SkiVacationPreparation;
