import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import lishale from "../assets/images/Hale.png";
import { Link } from "react-router-dom";

const BookingFrame: React.FC = () => {
  const [date, setDate] = useState<Date | null>(new Date());

  const handleDateChange = (
    value: Date | [Date | null, Date | null] | null
  ) => {
    if (Array.isArray(value)) {
      setDate(value[0] ?? null);
    } else {
      setDate(value);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-indigo-100 via-blue-50 to-gray-100 p-8">
      <div className="bg-white shadow-2xl rounded-3xl overflow-hidden w-full max-w-5xl grid grid-cols-1 md:grid-cols-2">
        {/* Left Section - Profile & Meeting Details */}
        <div className="p-8 bg-gradient-to-br from-white to-gray-50 border-r border-gray-200">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">
            Appointment With Lisa
          </h1>
          <div className="flex items-center mb-8">
            <img
              src={lishale}
              alt="Lisa Hale"
              className="w-16 h-16 rounded-full border-4 border-blue-200 shadow-lg"
            />
            <div className="ml-4">
              <h2 className="text-2xl font-semibold text-gray-800">
                Lisa Hale
              </h2>
              <p className="text-sm text-gray-500">30 Minute Meeting</p>
            </div>
          </div>

          <div className="mb-6">
            <p className="text-lg text-gray-600 flex items-center space-x-2">
              <span className="text-blue-600">⏳</span>
              <span>
                Duration: <strong className="font-medium">30 minutes</strong>
              </span>
            </p>
          </div>

          {/* User Input Fields */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Your Name
            </label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm transition ease-in-out"
              placeholder="Enter your name"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Phone Number
            </label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none shadow-sm transition ease-in-out"
              placeholder="Enter your phone number"
            />
          </div>
        </div>

        {/* Right Section - Calendar & Time Zone */}
        <div className="p-8 bg-gradient-to-br from-gray-50 to-white">
          <h3 className="text-xl font-semibold text-gray-800 mb-6">
            Select a Date
          </h3>
          <Calendar
            onChange={handleDateChange}
            value={date}
            className="w-full border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition ease-in-out"
          />

          {/* Time Zone Selection */}
          <div className="mt-8 flex items-center text-gray-700 space-x-2">
            <GlobeAltIcon className="h-6 w-6 text-blue-600" />
            <span className="text-sm font-medium">
              India Standard Time (IST)
            </span>
          </div>
          <div className="mt-5 mb-5">
            <Link to="/go">
              <button className="  py-1 px-6 border border-gray-800 uppercase text-gray-800 font-semibold rounded-lg hover:bg-gray-800 hover:text-white transition-all">
                Contact
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingFrame;
