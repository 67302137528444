import axios, { AxiosRequestConfig } from "axios";

// Define the static token
const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA"; // Replace with your actual token

// Define the API response type
export interface ApiResponse<T> {
  data: T;
  success: boolean;
  code: string;
  message: string;
}

export const fetchData = async <T>(
  options: AxiosRequestConfig
): Promise<ApiResponse<T> | null> => {
  try {
    const baseUrl = "https://travelnrelax.com/tadmin/api/";
    const apiUrl = options.url || "";
    const url = `${baseUrl}${apiUrl}`;

    const response = await axios.post<ApiResponse<T>>(url, options.data, {
      headers: {
        ...options.headers,
        token: STATIC_TOKEN,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios Error:", error.message);
      console.error("Error Details:", error.response?.data || error.toJSON());
    } else {
      console.error("Unexpected Error:", error);
    }
    return null;
  }
};
