import React, { useState, useEffect } from "react";
import Right from "../assets/images/Right-TravelMonth.svg";
import Left from "../assets/images/Left-TravelMonth.svg";
import axios from "axios";
import { useParams } from "react-router-dom";

interface OverviewData {
  overviewTitle: string;
  overviewDescription: string;
  overviewButtonText: string;
  overviewButtonLink: string;
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const OverviewCountry: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [overviewData, setOverviewData] = useState<OverviewData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const baseUrl = "https://travelnrelax.com/tadmin/api/";
        const response = await axios.post(
          `${baseUrl}/pages/CountryPageDetails`,
          { countryId: id },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success && response.data.data.overViewData) {
          setOverviewData(response.data.data.overViewData[0]);
        } else {
          throw new Error("Failed to fetch overview data.");
        }
      } catch (err) {
        console.error("Error fetching overview data:", err);
        setError("Failed to fetch overview data. Please try again later.");
      }
    };

    fetchOverviewData();
  }, []);

  return (
    <div className="relative">
      <div className="py-16 px-4 md:px-10 lg:px-20 text-center bg-white">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <>
            <h2 className="text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar text-center uppercase">
              {overviewData?.overviewTitle}
            </h2>
            <p className="lg:text-base sm:tracking-wide font-poppins mt-3 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
              {overviewData?.overviewDescription}
            </p>
            <button
              className="border border-black text-black px-6 py-2 mt-4 font-semibold hover:bg-gray-200 transition duration-300 ease-in-out"
              onClick={() => {
                if (overviewData?.overviewButtonLink) {
                  window.open(overviewData.overviewButtonLink, "_blank");
                }
              }}
            >
              {overviewData?.overviewButtonText}
            </button>
          </>
        )}
      </div>
      <div className="absolute top-0 right-0">
        <img src={Right} alt="Right Decoration" className="object-fill" />
      </div>
      <div className="absolute bottom-0 left-0">
        <img
          src={Left}
          alt="Left Decoration"
          className="h-80 w-full object-fill"
        />
      </div>
    </div>
  );
};

export default OverviewCountry;
