import React from "react";
import { FaRegCalendarAlt, FaRegComments } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface BlogCardProps {
  category: string;
  imageUrl: string;
  date: string;
  comments: number;
  title: string;
  description: string;
  author: string;
  authorImageUrl: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  category,
  imageUrl,
  date,
  comments,
  title,
  description,
  author,
  authorImageUrl,
}) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white rounded-lg mb-5 shadow  overflow-hidden">
      <div className="relative">
        <img src={imageUrl} alt={title} className="w-full h-52 object-cover" />

        <div
          className="absolute top-3 w-28 h-4 left-3 flex items-center justify-center font-alumnisans tracking-wide rounded font-semibold text-yellow text-sm uppercase px-7 py-3"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
        >
          {category}
        </div>
      </div>
      <div className="p-5" onClick={() => navigate("/blog")}>
        <div className="flex items-center text-sm text-gray-500 mb-3">
          <FaRegCalendarAlt className="mr-2 text-[#E6D783]" />
          <span className="font-anekbangla">{date}</span>
          <span className="mx-2 font-anekbangla">|</span>
          <FaRegComments className="mr-2 text-[#E6D783]" />
          <span className="font-anekbangla text-sm">{comments} Comments</span>
        </div>
        <h3 className="text-base font-bold text-[#01316E]  tracking-wide truncate font-alumnisans leading-5">
          {title}
        </h3>
        <p className=" mb-4 text-sm  font-poppins text-gray-700 border-b-2  py-3">
          {description}
        </p>
        <div className="flex justify-between  items-center w-full">
          <div className="flex">
            <img
              src={authorImageUrl}
              alt={author}
              className="w-10 h-10 rounded-full mr-3"
            />
            <span className="text-black mt-3 font-semibold text-sm uppercase font-akshar">
              {author}
            </span>
          </div>

          <button className="py-2 text-xs font-mplus2 px-2 uppercase border border-black text-black font-semibold rounded-md hover:bg-gray-200 transition">
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
