import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { fetchData, ApiResponse } from "../services/api";
import profileImage from "../assets/images/Rob.png"; 

interface ApiData {
  introduction: {
    id: string;
    text: string;
    description: string;
    image: string;
    buttonLink?: string;
    buttonText?: string;
  }[];
}

const RobDescription: React.FC = () => {
  const [introData, setIntroData] = useState<ApiData["introduction"][0] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY * 0.5);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchIntroData = async () => {
      try {
        const response: ApiResponse<ApiData> | null = await fetchData<ApiData>({
          url: "pages/get",
          data: { pageId: 26 },
        });

        console.log("API Response:", response);

        if (response?.data?.introduction?.length) {
          setIntroData(response.data.introduction[0]);
        } else {
          setError("No introduction data found.");
        }
      } catch (err) {
        setError("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchIntroData();
  }, []);

  return (
    <div
      className="relative bg-cover bg-center py-10 px-5 md:py-16 md:px-10 lg:px-20 overflow-hidden"
      style={{
        backgroundImage: `url(${introData?.image || ""})`,
        backgroundPosition: `center ${offsetY}px`,
      }}
    >
      <motion.div
        className="max-w-4xl mx-auto bg-white bg-opacity-90 rounded-lg shadow-lg p-8 md:p-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        {loading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : introData ? (
          <div className="flex flex-col items-center">
              <motion.img
            src={profileImage}
            alt="Rob Hale"
            className="w-32 h-32 rounded-full border-4 border-gray-300 shadow-md mb-6"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.3 }}
          />
            <motion.h1
              className="text-2xl md:text-3xl font-semibold text-gray-800 text-center mb-4 font-mplus2"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              {introData.text}
            </motion.h1>
            <motion.p
              className="text-sm md:text-base text-gray-600 text-center mb-8 font-akshar"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              {introData.description.split("\r\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </motion.p>

            {introData.buttonLink && introData.buttonText && (
              <a
                href={introData.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block py-2 px-6 border border-black text-black hover:bg-gray-700 hover:text-white transition rounded-md font-semibold"
              >
                {introData.buttonText}
              </a>
            )}
          </div>
        ) : (
          <p className="text-center text-gray-600">No data available.</p>
        )}
      </motion.div>
    </div>
  );
};

export default RobDescription;
