import React from "react";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import RobDetail from "../components/RobDetail";
import RobDescription from "../components/RobDescription";
import AdvisorCertification from "../components/AdvisorCertification";
import AdvisorTestimonial from "../components/AdvisorTestimonial";

const AdvisorDetail: React.FC = () => {
  return (
    <div className="">
      <Navbar />

      <RobDescription />
      <AdvisorCertification />
      <AdvisorTestimonial />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default AdvisorDetail;
