import React, { useState, useEffect } from "react";
import Rightimage from "../assets/images/Aboutus-img.svg";
import Leftimage from "../assets/images/Left-img.svg";
import { fetchData, ApiResponse } from "../services/api";

interface ApiData {
  intro: {
    id: string;
    text: string;
    description: string;
    image?: string;
    buttonLink?: string;
    buttonText?: string;
  }[];
}

const Aboutus: React.FC = () => {
  const [introData, setIntroData] = useState<{
    text: string;
    description: string;
    buttonLink?: string;
    buttonText?: string;
  } | null>(null);

  useEffect(() => {
    const fetchIntroData = async () => {
      try {
        const response: ApiResponse<ApiData> | null = await fetchData<ApiData>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        if (response?.data?.intro?.length) {
          const introItem = response.data.intro[0];
          setIntroData({
            text: introItem.text,
            description: introItem.description,
            buttonLink: introItem.buttonLink,
            buttonText: introItem.buttonText,
          });
        }
      } catch (error) {
        console.warn("Error fetching intro data. Please try again later.");
      }
    };

    fetchIntroData();
  }, []);

  if (!introData) return null; // ✅ Prevents unnecessary rendering while loading

  return (
    <div className="bg-white relative py-6 sm:py-16 px-3 sm:px-6 md:px-20 text-center font-akshar">
      <div className="absolute right-0 top-2 sm:top-0 w-16 sm:w-auto">
        <img src={Rightimage} alt="Seamless Travel" className="object-fill" />
      </div>

      <h2 className="text-xl lg:text-3xl font-akshar font-bold text-gray-900 tracking-wide">
        {introData.text}
      </h2>
      <p className="lg:text-base font-thin sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
        {introData.description}
      </p>
      {introData.buttonLink && (
        <div className="mt-6 sm:mt-8">
          <a
            href={introData.buttonLink}
            target="_blank"
            rel="noopener noreferrer"
            className="font-anekbangla inline-block py-2 px-7 border border-black text-black hover:bg-gray-700 hover:text-white transition rounded-md font-semibold"
          >
            {introData.buttonText}
          </a>
        </div>
      )}

      <div className="absolute left-0 bottom-2 sm:bottom-0 w-16 sm:w-36">
        <img src={Leftimage} alt="Seamless Travel" className="object-fill" />
      </div>
    </div>
  );
};

export default Aboutus;
