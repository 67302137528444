import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import NavMenu from "../components/NaveMenu";
import TravelOption from "../components/TravelOption";
import ContactSection from "../components/ContactSection";
import PromoBanner from "../components/PromoBanner";
import ExperienceCarousel from "../components/ExperienceCarousel";
import MonthGallery from "../components/MonthGallery";
import TabNavigation from "../components/TabNavigation";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { fetchData } from "../services/api";

const Month: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 16 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle:
              "Denver-Explore Exclusive Travel Themes | Travel N Relax – Adventure Awaits",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);
  return (
    <div>
      <Helmet>
        <meta name="tittle" content={metaData.pageTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
      </Helmet>
      <NavMenu />

      <MonthGallery />
      <PromoBanner />
      <ContactSection />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default Month;
